import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import VideoSkipForwardFifteenIcon from '@atlaskit/icon/core/video-skip-forward-fifteen';

const SKIP_SECONDS = 15;
const START_SECONDS = 0;

const i18n = defineMessages({
	skipForwardButtonLabel: {
		id: 'audio.miniplayer-controls.skip-forward-label',
		defaultMessage: 'Skip Forward',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will skip the currently active audio forward "x" seconds',
	},
});

export type SkipForwardButtonProps = {
	audioRef: React.RefObject<HTMLAudioElement>;
	isDisabled: boolean;
};

export const SkipForwardButton: FC<SkipForwardButtonProps> = ({ audioRef, isDisabled = false }) => {
	const intl = useIntl();

	const handleSkipForward = () => {
		const audio = audioRef.current;
		if (audio && audio.duration) {
			if (audio.currentTime >= audio.duration - SKIP_SECONDS) {
				audio.currentTime = START_SECONDS;
				setTimeout(() => audio.pause(), 100); // Small delay to ensure current time is reset and rendered before pausing
			} else {
				audio.currentTime += SKIP_SECONDS;
			}
		}
	};

	return (
		<IconButton
			icon={VideoSkipForwardFifteenIcon}
			shape="circle"
			label={intl.formatMessage(i18n.skipForwardButtonLabel)}
			tooltip={{ content: intl.formatMessage(i18n.skipForwardButtonLabel) }}
			isTooltipDisabled={false}
			appearance="subtle"
			testId="miniplayer-skip-forward"
			onClick={handleSkipForward}
			isDisabled={isDisabled}
		/>
	);
};
