import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import VideoPreviousIcon from '@atlaskit/icon/core/video-previous';

const START_SECONDS = 0;

const i18n = defineMessages({
	playFromBeginningLabel: {
		id: 'audio.miniplayer-controls.play-from-beginning-label',
		defaultMessage: 'Play from beginning',
		description:
			'The label is used for accessibility for the icon which will be shown in the menu item that will restart the active audio from the beginning',
	},
});

export type PlayFromBeginningButtonProps = {
	audioRef: React.RefObject<HTMLAudioElement>;
	isDisabled?: boolean;
};

export const PlayFromBeginningButton: FC<PlayFromBeginningButtonProps> = ({
	audioRef,
	isDisabled = false,
}) => {
	const intl = useIntl();

	const handlePlayFromBeginning = async () => {
		const audio = audioRef.current;
		if (audio) {
			audio.currentTime = START_SECONDS;
			if (audio.paused) {
				await audio.play();
			}
		}
	};

	return (
		<IconButton
			icon={VideoPreviousIcon}
			shape="circle"
			label={intl.formatMessage(i18n.playFromBeginningLabel)}
			tooltip={{ content: intl.formatMessage(i18n.playFromBeginningLabel) }}
			appearance="subtle"
			testId="miniplayer-play-from-beginning"
			onClick={handlePlayFromBeginning}
			isDisabled={isDisabled}
		/>
	);
};
