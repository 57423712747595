import type { FC } from 'react';
import React from 'react';

import { Flex, Grid } from '@atlaskit/primitives';

import { useAudioPlaybackState } from '../hooks/useAudioPlaybackState';

import { PlayPauseButton } from './PlayPauseButton';
import { MiniplayerPlaybackSpeedControl } from './MiniplayerPlaybackSpeedControl';
import { SkipForwardButton } from './SkipForwardButton';
import { SkipBackButton } from './SkipBackButton';
import { PlayFromBeginningButton } from './PlayFromBeginningButton';

export type MiniplayerControlsProps = {
	audioRef: React.RefObject<HTMLAudioElement>;
};

export const MiniplayerControls: FC<MiniplayerControlsProps> = ({ audioRef }) => {
	const [{ isLoading, isPlaying }] = useAudioPlaybackState();

	return (
		<Grid templateColumns="1fr auto 1fr" testId="miniplayer-controls">
			<Flex justifyContent="space-evenly" alignItems="center">
				<PlayFromBeginningButton audioRef={audioRef} isDisabled={isLoading} />
				<SkipBackButton audioRef={audioRef} isDisabled={isLoading} />
			</Flex>
			<PlayPauseButton audioRef={audioRef} isPlaying={isPlaying} isLoading={isLoading} />
			<Grid templateColumns="1fr 1fr">
				<Flex justifyContent="center" alignItems="center">
					<SkipForwardButton audioRef={audioRef} isDisabled={isLoading} />
				</Flex>
				<Flex justifyContent="center" alignItems="center">
					<MiniplayerPlaybackSpeedControl />
				</Flex>
			</Grid>
		</Grid>
	);
};
