import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import VideoSkipBackwardFifteenIcon from '@atlaskit/icon/core/video-skip-backward-fifteen';

const SKIP_SECONDS = 15;
const START_SECONDS = 0;

const i18n = defineMessages({
	skipBackButtonLabel: {
		id: 'audio.miniplayer-controls.skip-back-label',
		defaultMessage: 'Skip Back',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will skip the currently active audio back "x" seconds',
	},
});

export type SkipBackButtonProps = {
	audioRef: React.RefObject<HTMLAudioElement>;
	isDisabled?: boolean;
};

export const SkipBackButton: FC<SkipBackButtonProps> = ({ audioRef, isDisabled = false }) => {
	const intl = useIntl();

	const handleSkipBack = () => {
		const audio = audioRef.current;
		if (audio) {
			if (audio.currentTime <= SKIP_SECONDS) {
				audio.currentTime = START_SECONDS;
			} else {
				audio.currentTime -= SKIP_SECONDS;
			}
		}
	};

	return (
		<IconButton
			icon={VideoSkipBackwardFifteenIcon}
			shape="circle"
			label={intl.formatMessage(i18n.skipBackButtonLabel)}
			tooltip={{ content: intl.formatMessage(i18n.skipBackButtonLabel) }}
			isTooltipDisabled={false}
			appearance="subtle"
			testId="miniplayer-skip-back"
			onClick={handleSkipBack}
			isDisabled={isDisabled}
		/>
	);
};
